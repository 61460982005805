import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import { UserContext } from './providers/UserProvider.js';
import Articles from "./Articles";
import Events from "./Events";
import Login from "./Login.js";
import Artists from "./Artists.js";
import Backlog from "./Backlog.js";
import Cities from "./Cities.js";
import Subsites from "./Subsites.js";
import Organizers from "./Organizers.js";
import PushNotifications from "./PushNotifications.js";
import Reports from "./Reports.js";
import Users from "./Users.js";
import Genres from "./Genres.js";
import { auth } from "./firebase.js";
import Statistics from "./Statistics.js";
import StatsGeneral from "./Stats/statistics_general.jsx";
import Suggestions from "./Suggestions.js";
import Scrapers from "./Scrapers.js";
import Updates from "./Updates.js";
import Types from "./Types.js";
import Tickets from "./Tickets.js";
import Sales from "./Sales.js";
import Overview from "./Overview.js";
import RecurringEvents from "./RecurringEvents.js";
import AutoPosts from "./AutoPosts.js";
import Changelogs from "./Changelogs.js";

export default function App() {
  const { loaded, role, user } = useContext(UserContext);

  return loaded ? user ? ['admin', 'partner'].includes(role) ? <Router>
    <div className="h-full overflow-x-hidden">
      <Switch>
        <Route path="/articles" component={Articles} />
        <Route path="/artists" component={Artists} />
        <Route path="/auto-posts" component={AutoPosts} />
        <Route path="/backlog" component={Backlog} />
        <Route path="/cities" component={Cities} />
        <Route path="/subsites" component={Subsites} />
        <Route path="/events" component={Events} />
        <Route path="/genres" component={Genres} />
        <Route path="/organizers" component={Organizers} />
        <Route path="/pushnotifications" component={PushNotifications} />
        <Route path="/changelogs" component={Changelogs}/>
        <Route path="/recurring-events" component={RecurringEvents} />
        <Route path="/reports" component={Reports} />
        <Route path="/sales" component={Sales} />
        <Route path="/scraper" component={Scrapers} />
        <Route path="/statistics/:statistic" component={Statistics} />
        <Route path="/performance" component={StatsGeneral} />
        <Route path="/suggestions" component={Suggestions} />
        <Route path="/tickets" component={Tickets} />
        <Route path="/types" component={Types} />
        <Route path="/updates" component={Updates} />
        <Route path="/overview" component={Overview} />
        <Route path="/users" component={Users} />
        <Route path="/">
          <Redirect to="/events" />
        </Route>
      </Switch>
    </div>
  </Router> : <div className="p-3 w-full h-full flex flex-col items-center justify-center">
    <div>Normalsterbliche haben hier nichts zu suchen. Weg mit die Fiecher.</div>
    <button className="bg-blue-500 text-white rounded px-2 md:px-10 md:py-3 font-semibold mt-2" onClick={(_) => auth.signOut()}>Abhauen</button>
  </div> : <Login /> : <div className="p-3">Bitte warten...</div>;
}